<template>
  <!DOCTYPE html>
    <html>
        <head>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Génération de mail</title>
            <link rel="stylesheet" href="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/11.0.0/css/fabric.min.css"/>
            <link href="taskpane.css" rel="stylesheet" type="text/css" />
        </head>
  
        <body class="ms-font-m ms-welcome ms-Fabric ms-bgColor-white">
            <header class="ms-welcome__header ms-bgColor-neutralLighter">
                <img width="90" height="90" src="./assets/MacValves.png"/>
                <h1 class="ms-font-su">Générer un mail</h1>
                <fluent-select @change="onChange($event)" title="Select a template" v-model="key">
                    <fluent-option Value="">Please select one</fluent-option>
                    <fluent-option value="WrongParts">Wrong parts</fluent-option>
                    <fluent-option value="AmountToAdd">Somme à ajouter</fluent-option>
                    <fluent-option value="OrderCancellation">Order cancellation</fluent-option>
                    <fluent-option value="RMRequest">RM Request</fluent-option>
                    <fluent-option value="ESIRPrototype">ESIR Prototype</fluent-option>
                    <fluent-option value="ESIRPrototypeRaph">ESIR Prototype Raph</fluent-option>
                    <fluent-option value="CommercialOffer">Commercial offer</fluent-option>
                </fluent-select>
            </header>
            <div style="min-height: 800px; height: max-content;">
              <RouterView></RouterView>
            </div>
        </body>
    </html>
</template>

<script>
export default {
  name: 'App',
  props: {
  },
  data() {
    return { key: "" };
  },
  methods: {
    onChange(event) {
        switch (event.target.value) {
        case "":
          this.$router.push('/');
          break;
        case "WrongParts":
          this.$router.push('/WrongPartsForm');
          break;
        case "AmountToAdd":
          this.$router.push('/AmountToAddForm')
          break;
        case "OrderCancellation":
          this.$router.push('/OrderCancellationForm')
          break;
        case "RMRequest":
          this.$router.push('/RMRequestForm')
          break;
        case "ESIRPrototype":
          this.$router.push('/ESIRPrototypeForm')
          break;
        case "ESIRPrototypeRaph":
          this.$router.push('/ESIRPrototypeRaphForm')
          break;
        case "CommercialOffer":
          this.$router.push('/CommercialOfferForm')
          break;
        case "remove":
          break;
        }
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
