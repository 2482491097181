import { createRouter, createMemoryHistory } from 'vue-router'
import App from '../App.vue'
const routes = [
  {
    path: '/home',
    name: 'App',
    component: App
  },
  {
    path: '/WrongPartsForm',
    name: 'WrongPartsForm',
    component: () => import('../components/WrongParts/WrongPartsForm')
  },
  {
    path: '/WrongParts',
    name: 'WrongParts',
    component: () => import('../components/WrongParts/WrongParts')
  },
  {
    path: '/AmountToAddForm',
    name: 'AmountToAddForm',
    component: () => import('../components/AmountToAdd/AmountToAddForm')
  },
  {
    path: '/AmountToAdd',
    name: 'AmountToAdd',
    component: () => import('../components/AmountToAdd/AmountToAdd')
  },
  {
    path: '/OrderCancellationForm',
    name: 'OrderCancellationForm',
    component: () => import('../components/OrderCancellation/OrderCancellationForm')
  },
  {
    path: '/OrderCancellation',
    name: 'OrderCancellation',
    component: () => import('../components/OrderCancellation/OrderCancellation')
  },
  {
    path: '/RMRequestForm',
    name: 'RMRequestForm',
    component: () => import('../components/RMRequest/RMRequestForm')
  }, 
  {
    path: '/RMRequest',
    name: 'RMRequest',
    component: () => import('../components/RMRequest/RMRequest')
  },
  {
    path: '/ESIRPrototypeForm',
    name: 'ESIRPrototypeForm',
    component: () => import('../components/ESIRPrototype/ESIRPrototypeForm')
  }, 
  {
    path: '/ESIRPrototype',
    name: 'ESIRPrototype',
    component: () => import('../components/ESIRPrototype/ESIRPrototype')
  },
  {
    path: '/ESIRPrototypeRaphForm',
    name: 'ESIRPrototypeRaphForm',
    component: () => import('../components/ESIRPrototype/ESIRPrototypeRaphForm')
  }, 
  {
    path: '/ESIRPrototypeRaph',
    name: 'ESIRPrototypeRaph',
    component: () => import('../components/ESIRPrototype/ESIRPrototypeRaph')
  },
  {
    path: '/CommercialOfferForm',
    name: 'CommercialOfferForm',
    component: () => import('../components/CommercialOffer/CommercialOfferForm')
  }, 
  {
    path: '/CommercialOffer',
    name: 'CommercialOffer',
    component: () => import('../components/CommercialOffer/CommercialOffer')
  }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

export default router
